import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Header from "../components/Header"
import Seo from './seo'
import Layout from '../layout/global'
import * as designSystem from '../components/DesignSystem'
import * as globalCls from './../layout/Global.module.css'

const shortcodes = designSystem.shortcodesBtnLight

// Prevent 404 page from loading when GatsbyJS renders page
const browser = typeof window !== "undefined" && window;


const pageTitle = "Not Found"
const pageDescription = "The page you are looking for does not exist."

const NotFoundPage = () => {
  return (
    browser && (
      <main>
        <Seo title={pageTitle} description={pageDescription} />
        <Header pageName={pageTitle} pageDescription={pageDescription} />
        <Layout>
          <section className={`${globalCls.section}`}>
            <div className={`${globalCls.padding}`}>
              <h1><strong>Page Not Found</strong></h1>
              <p>The page you are looking for does not exist...</p>
              <small>(unless you were looking for a really blank page...in which case you found it)</small>
              <shortcodes.p>
                <shortcodes.a href="/">
                  Navigate to Home
                </shortcodes.a>
              </shortcodes.p>
            </div>
          </section>
        </Layout>
      </main>
    )
  )
}

export default NotFoundPage
